import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/User/Login.vue'
import Demo from '../views/Demo/Index.vue'
import SearchGoods from '@/views/Goods/SearchGoods.vue'
import { versionUpdate } from './plugins/versionUpdate'
import { authGuard } from './plugins/authGuard'
import { loginRedirect } from './plugins/loginRedirect'
import { closeChatBox } from '@/utils/chat'
import { progressBar } from './plugins/progressBar'
import { firstVisit } from './plugins/firstVisit'
import { weChat } from './plugins/weChat'
import { saveScrollPosition, getScrollPosition } from './plugins/scrollPosition'
import dingShare from '@/utils/dingShare'
import { log } from '@/utils/log'
import { setTitle } from './plugins/setTitle'
import { device } from '@/store'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      noAuth: true,
    },
  },
  //演示用
  {
    path: '/demo',
    name: 'demo',
    component: Demo,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/RedirectWeapp',
    name: 'RedirectWeapp',
    component: () => import('../views/Other/Redirectweapp.vue'),
    meta: {
      noAuth: true,
      skipGuard: true,
      title: '好货通',
    },
  },
  {
    path: '/Scanready',
    name: 'Scanready',
    component: () => import('../views/User/Scanready.vue'),
    meta: {
      noAuth: true,
      skipGuard: true,
      title: '好货通',
    },
  },
  {
    path: '/Redirectbank',
    name: 'Redirectbank',
    component: () => import('../views/Other/Redirectbank.vue'),
    meta: {
      noAuth: true,
      skipGuard: true,
      title: '好货通',
    },
  },
  {
    path: '/DingLogin/:corpid',
    name: 'DingLogin',
    component: () => import('../views/User/DingLogin.vue'),
    meta: {
      noAuth: true,
      title: '钉钉登录',
    },
  },
  {
    path: '/FirstLogin',
    name: 'FirstLogin',
    component: () => import('../views/User/FirstLogin.vue'),
    meta: {
      title: '首次登录',
    },
  },
  {
    path: '/Goods',
    name: 'Goods',
    component: () => import('../views/Goods/Goods.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '首页',
      weappPath: 'pages/goods/index',
    },
  },
  {
    path: '/CustomLayout',
    name: 'CustomLayout',
    component: () => import('../views/CustomLayout/Index.vue'),
    meta: {
      title: '微页面',
      weappPath: 'pages/custom-layout/index',
      keepAlive: true,
    },
  },
  {
    path: '/newgoods',
    name: 'NewGoods',
    component: () => import('../views/Goods/NewGoods.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '新品专区',
      weappPath: 'pages/goods/newgoods/index',
    },
  },
  {
    path: '/groupgoods',
    name: 'GroupGoods',
    component: () => import('../views/Goods/GroupGoods.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '分组商品',
      weappPath: 'pages/goods/groupgoods/index',
    },
  },
  {
    path: '/hotsale',
    name: 'HotSale',
    component: () => import('../views/Rank/Index.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '热卖排行',
      weappPath: 'pages/goods/hotsale/index',
    },
  },
  {
    path: '/hotsale-retail',
    name: 'HotSaleRetail',
    component: () => import('../views/Rank/Retail.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '热卖排行',
      weappPath: 'pages/goods/hotsale/retail',
    },
  },
  {
    path: '/specialoffer',
    name: 'SpecialOffer',
    component: () => import('../views/Goods/SpecialOffer.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '特价专区',
      weappPath: 'pages/goods/specialoffer/index',
    },
  },
  {
    path: '/searchgoods',
    name: 'SearchGoods',
    component: SearchGoods,
    meta: {
      keepAlive: true,
      navigation: 'goods',
      isBack: false,
      title: '搜索',
      weappPath: 'pages/goods/searchgoods/index',
    },
  },
  {
    path: '/presellgoods',
    name: 'PresellGoods',
    component: () => import('../views/Goods/PresellGoods.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '现货预售',
      weappPath: 'pages/goods/presellgoods/index',
    },
  },
  {
    path: '/futuresgoods',
    name: 'FuturesGoods',
    component: () => import('../views/Goods/FuturesGoods.vue'),
    meta: {
      keepAlive: true,
      navigation: 'goods',
      title: '期货预订',
      weappPath: 'pages/goods/futuresgoods/index',
    },
  },
  {
    path: '/GoodsDetail',
    name: 'GoodsDetail',
    component: () => import('../views/Goods/GoodsDetail.vue'),
    meta: {
      navigation: 'goods',
      title: '商品详情',
      weappPath: 'pages/goods/detail/index',
    },
  },
  {
    path: '/GoodsNotFound',
    name: 'GoodsNotFound',
    component: () => import('../views/Goods/GoodsNotFound.vue'),
    meta: {
      navigation: 'goods',
      title: '商品404',
    },
  },
  {
    path: '/GoodsUnAuthorized',
    name: 'GoodsUnAuthorized',
    component: () => import('../views/Goods/GoodsUnAuthorized.vue'),
    meta: {
      navigation: 'goods',
      title: '商品无权访问',
      weappPath: 'pages/goods/unauthorized/index',
    },
  },
  {
    path: '/Classify',
    name: 'Classify',
    component: () => import('../views/Classify/Classify.vue'),
    meta: {
      keepAlive: true,
      navigation: 'classify',
      title: '分类',
      weappPath: 'pages/classify/classify',
    },
  },
  {
    path: '/OrderList',
    name: 'OrderList',
    component: () => import('../views/Order/Index.vue'),
    meta: {
      keepAlive: true,
      navigation: 'order',
      title: '订单',
      weappPath: 'pages/order/index',
    },
  },
  {
    path: '/CashOrder',
    name: 'CashOrder',
    component: () => import('../views/Order/CashOrder/Index.vue'),
    meta: {
      navigation: 'order',
      title: '现货订单',
    },
  },
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: () => import('../views/Order/Detail/Index.vue'),
    meta: {
      navigation: 'order',
      title: '订单详情',
      weappPath: 'pages/order/future/detail',
    },
  },
  {
    path: '/FutureList',
    name: 'FutureList',
    component: () => import('../views/Order/Future/List.vue'),
    meta: {
      navigation: 'order',
      title: '期货订单',
      weappPath: 'pages/order/future/list',
    },
  },
  {
    path: '/FutureDetail',
    name: 'FutureDetail',
    component: () => import('../views/Order/Future/Detail.vue'),
    meta: {
      navigation: 'order',
      title: '订单详情',
      weappPath: 'pages/order/future/detail',
    },
  },
  {
    path: '/jinfuyidaohuo',
    name: 'JinFuYiDaoHuo',
    component: () => import('../views/Order/JinFuYiDaoHuo/Index.vue'),
    meta: {
      navigation: 'order',
      title: '仅付已到货',
    },
  },
  {
    path: '/OrderNotFound',
    name: 'OrderNotFound',
    component: () => import('../views/Order/NotFound.vue'),
    meta: {
      navigation: 'order',
      title: '订单404',
    },
  },
  {
    path: '/PackageList',
    name: 'PackageList',
    component: () => import('../views/Order/PackageList/Index.vue'),
  },
  {
    path: '/Payment',
    name: 'Payment',
    component: () => import('../views/Payment/Payment/Index.vue'),
    meta: {
      title: '支付页',
      weappPath: 'pages/payment/index',
    },
  },
  {
    path: '/SuccessApply',
    name: 'SuccessApply',
    component: () => import('../views/Payment/Payment/SuccessApply.vue'),
    meta: {
      title: '申请成功',
    },
  },
  {
    path: '/UnderStock',
    name: 'UnderStock',
    component: () => import('../views/Payment/UnderStock/UnderStock.vue'),
    meta: {
      title: '商品库存不足',
    },
  },
  {
    path: '/Bank',
    name: 'Bank',
    component: () => import('../views/Payment/Bank/Index.vue'),
    meta: {
      title: '我的银行卡',
      weappPath: 'pages/payment/bank/index/index',
    },
  },
  {
    path: '/Bank/Add',
    name: 'BankAdd',
    component: () => import('../views/Payment/Bank/Add.vue'),
    meta: {
      title: '增加银行卡',
      weappPath: 'pages/payment/bank/add/index',
    },
  },
  {
    path: '/Address',
    name: 'Address',
    component: () => import('../views/Payment/Address/Index.vue'),
    meta: {
      title: '收货地址',
      weappPath: 'pages/payment/address/list',
    },
  },
  {
    path: '/Mine',
    name: 'Mine',
    component: () => import('../views/User/Mine/Index.vue'),
    meta: {
      navigation: 'mine',
      title: '个人中心',
      weappPath: 'pages/mine/mine',
    },
  },
  {
    path: '/RequireGoodsList',
    name: 'RequireGoods',
    component: () => import('../views/User/RequireGoodsList/Index.vue'),
    meta: {
      keepAlive: true,
      navigation: 'mine',
      title: '门店要货单',
      weappPath: 'pages/order/index',
    },
  },
  {
    path: '/RequireGoodsList/FutureDetail',
    name: 'RequireGoodsFutureDetail',
    component: () => import('../views/User/RequireGoodsList/Future/Detail.vue'),
    meta: {
      title: '要货单详情',
      weappPath: 'pages/order/index',
    },
  },
  {
    path: '/RequireGoodsList/OrderDetail',
    name: 'RequireGoodsOrderDetail',
    component: () => import('../views/User/RequireGoodsList/Detail/Index.vue'),
    meta: {
      title: '要货单详情',
      weappPath: 'pages/order/index',
    },
  },
  {
    path: '/SelectCompany',
    name: 'SelectCompany',
    component: () => import('../views/User/Mine/selectCompany.vue'),
    meta: {
      title: '切换组织身份',
      selectCompany: true,
      weappPath: 'pages/mine/selectcompany/index',
    },
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: () => import('../views/User/Settings.vue'),
    meta: {
      title: '设置',
      weappPath: 'pages/user/settings/index',
    },
  },
  {
    path: '/Mine/Menu',
    name: 'MineMenu',
    component: () => import('../views/Mine/Menu.vue'),
    meta: {
      navigation: 'mine',
      title: '菜单',
    },
  },
  {
    path: '/Mine/MenuOperate',
    name: 'MineMenuOperate',
    component: () => import('../views/Mine/MenuOperate.vue'),
    meta: {
      navigation: 'mine',
      title: '修改菜单',
    },
  },
  {
    path: '/EditOldPassword',
    name: 'EditOldPassword',
    component: () => import('../views/User/EditPassword.vue'),
    meta: {
      navigation: 'mine',
      title: '修改密码',
      weappPath: 'pages/user/edit-password/index',
    },
  },
  {
    path: '/EditPassword',
    name: 'EditPassword',
    component: () => import('../views/User/PasswordEdit.vue'),
    meta: {
      navigation: 'mine',
      title: '修改密码',
      weappPath: 'pages/user/password-edit/index',
    },
  },
  {
    path: '/EditAccount',
    name: 'EditAccount',
    component: () => import('../views/User/Mine/EditAccount.vue'),
    meta: {
      navigation: 'mine',
      title: '修改收款账户',
      weappPath: 'pages/user/account-edit/index',
    },
  },
  {
    path: '/Mine/UserList',
    name: 'MineUserList',
    component: () => import('../views/Mine/UserList.vue'),
    meta: {
      keepAlive: true,
      navigation: 'mine',
      title: '用户列表',
      weappPath: 'pages/mine/mineuserlist/mineuserlist',
    },
  },
  {
    path: '/Mine/User/:id',
    name: 'MineUserDetail',
    component: () => import('../views/Mine/UserDetail.vue'),
    meta: {
      navigation: 'mine',
      title: '用户详情',
      weappPath: 'pages/mine/mineuserlist/detail/detail',
    },
  },
  {
    path: '/Finance',
    name: 'Finance',
    component: () => import('../views/Other/Finance/List.vue'),
    meta: {
      navigation: 'mine',
      title: '财务对账',
      weappPath: 'pages/mine/finance/finance',
    },
  },
  {
    path: '/Refund',
    name: 'Refund',
    component: () => import('../views/Order/Refund/List.vue'),
    meta: {
      navigation: 'order',
      title: '退款/售后',
      weappPath: 'pages/order/refund/list',
      keepAlive: true,
    },
  },
  {
    path: '/Refund/:id',
    name: 'RefundDetail',
    component: () => import('../views/Order/Refund/Detail.vue'),
    meta: {
      navigation: 'order',
      title: '退款详情',
      weappPath: 'pages/order/refund/detail',
    },
  },
  {
    path: '/Express/:id',
    name: 'Express',
    component: () => import('../views/Order/Express/Index.vue'),
    meta: {
      navigation: 'order',
      title: '包裹信息查看',
      weappPath: 'pages/order/express/express',
    },
  },
  {
    path: '/RefundProcedures/:id',
    name: 'RefundProcedures',
    component: () => import('../views/Order/Refund/Procedures.vue'),
    meta: {
      navigation: 'order',
      title: '协商历史',
      weappPath: 'pages/order/refund/procedures',
    },
  },
  {
    path: '/ReturnInstructions',
    name: 'ReturnInstructions',
    component: () => import('../views/Other/ReturnInstructions.vue'),
    meta: {
      title: '退换货说明',
      weappPath: 'pages/other/return-instructions/index',
    },
  },
  {
    path: '/Policystatement',
    name: 'Policystatement',
    component: () => import('../views/Goods/Policystatement.vue'),
    meta: {
      title: '政策解读',
      weappPath: 'pages/goods/policystatement/policystatement',
    },
  },
  {
    path: '/InviteCode',
    name: 'InviteCode',
    component: () => import('@/views/Other/InviteCode.vue'),
    meta: {
      title: '我的邀约码',
      weappPath: 'pages/other/invite-code/index',
    },
  },
  {
    path: '/Logistics',
    name: 'Logistics',
    component: () => import('@/views/User/Mine/Logistics.vue'),
    meta: {
      title: '物流查询',
      weappPath: 'pages/other/logistics/index',
    },
  },
  {
    path: '/WholesaleRank',
    name: 'WholesaleRank',
    component: () => import('../views/Rank/Index.vue'),
    meta: {
      title: '批发热卖榜单',
    },
  },
  {
    path: '/WholesaleDetail',
    name: 'WholesaleDetail',
    component: () => import('../views/Rank/Detail.vue'),
    meta: {
      title: '热卖榜单',
    },
  },
  {
    path: '/RetailRank',
    name: 'RetailRank',
    component: () => import('../views/Rank/Retail.vue'),
    meta: {
      title: '零售热卖榜单',
    },
  },
  {
    path: '/allrank',
    name: 'AllRank',
    component: () => import('../views/Rank/Rank.vue'),
    meta: {
      title: '排行总榜',
    },
  },
  {
    path: '/wholerank',
    name: 'WholeRank',
    component: () => import('../views/Rank/WholeRank.vue'),
    meta: {
      title: '排行总榜',
    },
  },

  {
    path: '/RetailDetail',
    name: 'RetailDetail',
    component: () => import('../views/Rank/RetailDetail.vue'),
    meta: {
      title: '热卖榜单',
    },
  },

  {
    path: '/Purchase',
    name: 'Purchase',
    component: () => import('../views/Purchase/Index.vue'),
    meta: {
      keepAlive: true,
      navigation: 'purchase',
      title: '采购单',
      weappPath: 'pages/purchase/index',
    },
  },
  {
    path: '/Purchase/Futures',
    name: 'PurchaseFutures',
    component: () => import('../views/Purchase/Futures/Index.vue'),
    meta: {
      title: '期货采购单',
      weappPath: 'pages/purchase/futures/index',
    },
  },
  {
    path: '/AdultAnalysis',
    name: 'AdultAnalysis',
    component: () => import('../views/Order/Analysis/Adult.vue'),
    meta: {
      keepAlive: true,
      title: '订货分析',
      weappPath: 'pages/order/adultanalysis/index',
    },
  },
  {
    path: '/ChildrenAnalysis',
    name: 'ChildrenAnalysis',
    component: () => import('../views/Order/Analysis/Children.vue'),
    meta: {
      keepAlive: true,
      title: '订货分析',
      weappPath: 'pages/order/analysis/index',
    },
  },
  /* 商品清单 */
  {
    path: '/SearchOrder',
    name: 'SearchOrder',
    component: () => import('../views/Order/Analysis/SearchOrder.vue'),
    meta: {
      keepAlive: true,
      title: '期货订单',
      weappPath: 'pages/order/futureSearch/list',
    },
  },
  {
    path: '/AdultAnalysis/OrderDetail',
    name: 'AdultAnalysisOrderDetail',
    component: () => import('../views/Order/Analysis/OrderDetail.vue'),
    meta: {
      keepAlive: true,
      title: '订货明细',
    },
  },
  {
    path: '/RemainRecord',
    name: 'RemainRecord',
    component: () => import('../views/User/Mine/RemainRecord.vue'),
    meta: {
      title: '剩余可用额度记录',
      weappPath: 'pages/mine/remainrecord/index',
    },
  },
  /* 待还授信订单 */
  {
    path: '/PaidOrder',
    name: 'PaidOrder',
    component: () => import('../views/User/Mine/PaidOrder.vue'),
    meta: {
      keepAlive: true,
      title: '待还授信订单',
      weappPath: 'pages/mine/paidorder/index',
    },
  },
  /* 冻结订单 */
  {
    path: '/FreezeOrder',
    name: 'FreezeOrder',
    component: () => import('../views/User/Mine/FreezeOrder.vue'),
    meta: {
      keepAlive: true,
      title: '订单列表',
      weappPath: 'pages/mine/freezeorder/index',
    },
  },
  /* 商品清单 */
  {
    path: '/GoodsInventory',
    name: 'GoodsInventory',
    component: () => import('../views/Order/Analysis/GoodsInventory.vue'),
    meta: {
      keepAlive: true,
      title: '商品清单',
      weappPath: 'pages/order/goodsinventory/index',
    },
  },
  /* 期货订单详情 */
  {
    path: '/FutureSearch',
    name: 'FutureSearch',
    component: () => import('../views/Order/Analysis/FutureSearch.vue'),
    meta: {
      keepAlive: true,
      title: '开发款数',
      weappPath: 'pages/order/futureGoods/index',
    },
  },
  /* 期货订单详情 */
  {
    path: '/RePayment',
    name: 'RePayment',
    component: () => import('../views/Payment/Payment/RePayment.vue'),
    meta: {
      title: '授信还款',
      weappPath: 'pages/mine/repayment/index',
    },
  },
  /* 期货订单详情 */
  {
    path: '/WholeSalePay',
    name: 'WholeSalePay',
    component: () => import('../views/Payment/Payment/WholeSalePay.vue'),
    meta: {
      title: '银行转账打款',
      weappPath: 'pages/mine/wholesalepay/index',
    },
  },
  {
    path: '/PlaceOrder',
    name: 'PlaceOrder',
    component: () => import('../views/User/Mine/PlaceOrder.vue'),
    meta: {
      title: '发货跟进表',
      weappPath: 'pages/mine/placeorder/index',
    },
  },
  {
    path: '/ScanLogin/:key',
    name: 'ScanLogin',
    component: () => import('../views/User/ScanLogin.vue'),
    meta: {
      title: '扫码登录',
    },
  },
  {
    path: '/AccountCheck',
    name: 'AccountCheck',
    component: () => import('../views/User/Mine/AccountCheck.vue'),
    meta: {
      title: '对账台',
    },
  },
  {
    path: '/FollowOrder',
    name: 'FollowOrder',
    component: () => import('../views/User/Mine/FollowOrder.vue'),
    meta: {
      title: '订货跟进表',
    },
  },
  {
    path: '/FollowOrderDetail',
    name: 'FollowOrderDetail',
    component: () => import('../views/User/Mine/FollowOrderDetail.vue'),
    meta: {
      title: '跟进表详细',
    },
  },

  {
    path: '/FutureAgree',
    name: 'FutureAgree',
    component: () => import('../views/User/FutureAgree.vue'),
    meta: {
      title: '意向金协议',
    },
  },
  {
    path: '/SendRefund',
    name: 'SendRefund',
    component: () => import('../views/Order/CanRefund/Index.vue'),
    meta: {
      title: '选择售后商品',
    },
  },
  {
    path: '/NotSendRefund',
    name: 'NotSendRefund',
    component: () => import('../views/Order/CanRefund/NotSend.vue'),
    meta: {
      title: '选择退款商品',
    },
  },
  {
    path: '/SuccessRefund',
    name: 'SuccessRefund',
    component: () => import('../views/Order/CanRefund/Success.vue'),
    meta: {
      title: '退款申请',
    },
  },
  {
    path: '/scan-check',
    name: 'HDBScanCheck',
    component: () => import('@/views/User/ScanCheck/Index.vue'),
    meta: {
      title: '盘点单',
    },
  },
  {
    path: '/scan-check/:id',
    name: 'HDBScanCheckDetail',
    component: () => import('@/views/User/ScanCheck/Detail.vue'),
    meta: {
      title: '扫码盘点详情',
    },
  },
  {
    path: '/scan-sign',
    name: 'ScanSign',
    component: () => import('@/views/User/SignStock/Index.vue'),
    meta: {
      title: '扫码入库',
    },
  },
  {
    path: '/scan-sign/:id',
    name: 'ScanSignDetail',
    component: () => import('@/views/User/SignStock/Detail.vue'),
    meta: {
      title: '新建入库单',
    },
  },
  {
    path: '/send-sample',
    name: 'SendSample',
    component: () => import('@/views/User/SendSample/Index.vue'),
    meta: {
      title: '样品发货单',
    },
  },
  {
    path: '/send-sample/:id',
    name: 'SendSampleDetail',
    component: () => import('@/views/User/SendSample/Detail.vue'),
    meta: {
      title: '发货单详情',
    },
  },
  {
    path: '/send-express/:id',
    name: 'SendSampleExpress',
    component: () => import('@/views/User/SendSample/Express.vue'),
    meta: {
      title: '包裹信息查看',
    },
  },
  {
    path: '/hdb-label-print',
    name: 'HDBLabelPrint',
    component: () => import('@/views/User/ScanCheck/Detail.vue'),
    meta: {
      title: '标签打印',
      weappPath: 'pages/index/print/index',
    },
  },

  {
    path: '/sealedscan',
    name: 'SealedScan',
    component: () => import('@/views/User/ScanCheck/SealedScan.vue'),
    meta: {
      title: '封样签收',
    },
  },
  {
    path: '/sample-review',
    name: 'SampleReview',
    component: () => import('@/views/User/SampleReview/Index.vue'),
    meta: {
      title: '样品评审',
    },
  },

  {
    path: '/review-analysis',
    name: 'ReviewAnalysis',
    component: () => import('@/views/User/SampleReview/Index.vue'),
    meta: {
      title: '评审多维分析',
      weappPath: 'pages/index/review/analysis/index',
    },
  },

  {
    path: '/tag-print',
    name: 'TagPrint',
    component: () => import('@/views/User/SampleReview/Index.vue'),
    meta: {
      title: '标签打印',
      weappPath: 'pages/index/print/index',
    },
  },

  {
    path: '/fair-distribution',
    name: 'FairDistribution',
    component: () => import('@/views/Order/FairDistribution/Index.vue'),
    meta: {
      title: '订货会配货',
    },
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('@/views/User/Information/Index.vue'),
    meta: {
      title: '资讯',
      keepAlive: true,
    },
  },
  {
    path: '/informationdetail',
    name: 'InformationDetail',
    component: () => import('@/views/User/Information/Detail.vue'),
    meta: {
      title: '资讯详情',
    },
  },
  {
    path: '/informationfile',
    name: 'InformationFile',
    component: () => import('@/views/User/Information/File.vue'),
    meta: {
      title: '获取资料',
    },
  },
  {
    path: '/futuresrank',
    name: 'FuturesRank',
    component: () => import('@/views/Goods/FuturesRank/Index.vue'),
    meta: {
      title: '期货补单排行',
      keepAlive: true,
    },
  },
  //小程序嵌套 供应商预览
  {
    path: '/supplyreport',
    name: 'SupplyReport',
    component: () => import('@/views/Other/SupplyReport/Index.vue'),
    meta: {
      title: '供应商概览',
      keepAlive: true,
    },
  },
  //小程序嵌套 供应商预览
  {
    path: '/supplyreport/detail',
    name: 'SupplyReportDetail',
    component: () => import('@/views/Other/SupplyReport/detail.vue'),
    meta: {
      title: '报表单品',
      keepAlive: true,
    },
  },
  {
    path: '/sales-report',
    name: 'SalesReport',
    component: () => import('@/views/User/Mine/SalesReport.vue'),
    meta: {
      title: '门店销售报表',
      keepAlive: true,
      weappPath: 'pages/mine/salesreport/index',
    },
  },
  {
    path: '/stockQuota',
    name: 'StockQuota',
    component: () => import('@/views/User/Mine/StockQuota.vue'),
    meta: {
      title: '门店库存指标',
      keepAlive: true,
      weappPath: 'pages/mine/stockQuota/index',
    },
  },
  {
    path: '/stockPlan',
    name: 'StockPlan',
    component: () => import('@/views/User/Mine/StockPlan.vue'),
    meta: {
      title: '门店进货规划',
      keepAlive: true,
      weappPath: 'pages/mine/stockPlan/index',
    },
  },
  // 反馈记录
  {
    path: '/feedbackrecord',
    name: 'FeedbackRecord',
    component: () => import('@/views/User/Mine/FeedbackRecord.vue'),
    meta: {
      title: '反馈记录',
      keepAlive: true,
      weappPath: 'pages/mine/feedback/record',
    },
  },
  // 意见反馈
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/User/Mine/Feedback.vue'),
    meta: {
      title: '意见反馈',
      keepAlive: true,
      weappPath: 'pages/mine/feedback/index',
    },
  },
  // 反馈详情
  {
    path: '/feedbackInfo',
    name: 'FeedbackInfo',
    component: () => import('@/views/User/Mine/FeedbackInfo.vue'),
    meta: {
      title: '反馈详情',
      keepAlive: true,
      weappPath: 'pages/mine/feedback/Info',
    },
  },
  {
    path: '/feedbackList',
    name: 'FeedbackList',
    component: () => import('../views/User/Mine/FeedbackList/Index.vue'),
    meta: {
      keepAlive: true,
      title: '投诉/建议',
      weappPath: 'pages/mine/feedbackList/index',
    },
  },
  {
    path: '/feedbackListShare',
    name: 'FeedbackListShare',
    component: () => import('../views/User/Mine/FeedbackList/Share.vue'),
    meta: {
      noAuth: true,
      keepAlive: true,
      title: '意见反馈',
      weappPath: 'pages/mine/feedbackListShare/index',
    },
  },
  {
    path: '/feedbackReply',
    name: 'FeedbackReply',
    component: () => import('../views/User/Mine/FeedbackList/Reply.vue'),
    meta: {
      keepAlive: true,
      title: '回复',
      weappPath: 'pages/mine/feedbackList/reply',
    },
  },
  /* 小程序打开文件 */
  {
    path: '/openfile',
    name: 'OpenFile',
    component: {},
    meta: {
      noAuth: true,
      skipGuard: true,
      weappPath: 'pages/other/open-file/index',
    },
  },
  /* 准备页 特殊功能页 详情见 plugins/firstVisit */
  {
    path: '/Ready',
    name: 'Ready',
    component: {},
    meta: {
      noAuth: true,
      skipGuard: true,
    },
  },
  { path: '/:pathMatch(.*)*', redirect: '/Goods' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: getScrollPosition,
})
saveScrollPosition(router)
/* plugins */
versionUpdate(router)
authGuard(router)
loginRedirect(router)
progressBar(router)
setTitle(router)
firstVisit(router)
weChat(router)

closeChatBox(router)

router.afterEach((to, from) => {
  let title = to.meta.title ? to.meta.title : '好货通'
  if (!device.isWeChatMini) {
    title += '-好货通'
  }
  document.title = title
  if (device.isDingTalk) {
    device.setTitle(title)
  }
  dingShare()
})

/*埋点*/
log(router)

export default router
